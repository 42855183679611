import React from "react";
import facebook from './facebook.png';
import twitter from './twitter.png';
import insta from './instagram.png';
import linked from './linked.png';
import link from './link.png';
import { Grid } from "@material-ui/core";
import { Link } from "gatsby";

const SocialFlow=()=> {
    const copy = async () => {
        await navigator.clipboard.writeText(window.location.href);
      }
  return (
    <Grid container>
        <Link to={'https://www.facebook.com/Sydetasker2020/'}>
        <Grid item xs={2} md={2} lg={2} xl={2}>
            <img loading="lazy" style={{width:"24px",height:"24px"}} src={facebook}></img>
        </Grid>
        </Link>
        <Link to={'https://www.instagram.com/sydetaskerapp/?hl=en'}>
        <Grid item xs={2} md={2} lg={2} xl={2}>
            <img loading="lazy" style={{width:"24px",height:"24px"}} src={insta}></img>
        </Grid>
        </Link>
        <Link to={'https://twitter.com/sydetasker'}>
        <Grid item xs={2} md={2} lg={2} xl={2}>
            <img loading="lazy" style={{width:"24px",height:"24px"}} src={twitter}></img>
        </Grid>
        </Link>
        <Grid  item xs={2} md={2} lg={2} xl={2}>
        <img loading="lazy" onClick={copy} style={{width:"24px",height:"24px"}} src={link}></img>
        </Grid>  
    </Grid>
  );
}

export default  SocialFlow;